.navbar-toggler-custom {
    border: none;
    box-shadow: none;
  }
  
  .navbar-toggler-custom:focus {
    outline: none;
    box-shadow: none;
  }
  a{
    color: white;
  }

  .mobile-offcanvas  .search-container{
    width: 100% !important;
  }
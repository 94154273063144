/* Messages.css */

.chat-container {
    display: flex;
    height: 100%;
    position: relative;
    overflow: hidden;
    /* Garante que o conteúdo fora da tela não seja visível */
}

.chat-list,
.chat-window {
    width: 100%;
    position: relative;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

/* Mobile (max-width 992px): sobrepõe o chatWindow ao chatList */
@media (max-width: 992px) {

    .chat-list,
    .chat-window {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }

    @keyframes slideInFromRight {
        from {
            transform: translateX(100%);
            opacity: 0;
        }

        to {
            transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes slideOutToRight {
        from {
            transform: translateX(0);
            opacity: 1;
        }

        to {
            transform: translateX(100%);
            opacity: 0;
        }
    }

    /* Animações para a lista e o chat no mobile */
    .slide-in {
        animation: slideInFromRight 0.5s ease forwards;
    }

    .slide-out {
        animation: slideOutToRight 0.5s ease forwards;
    }
}

/* Desktop (min-width 993px): lado a lado */
@media (min-width: 993px) {
    .chat-container {
        display: flex;
        flex-direction: row;

    }

    .chat-list {
        width: 40%;
        max-width: 520px;
    }

    .chat-window {
        width: 60%;
        border-left: 1px solid #ddd;
    }

    /* No desktop, não há animações */
    .slide-in,
    .slide-out {
        animation: none;
    }
}


@media (max-width: 993px) {
    .chat-container {
        height: calc(100svh - 160px);
    }
}
.d-flex {
    display: flex;
}

.flex-grow-1 {
    flex-grow: 1;
    overflow: auto;
}

.content {
    padding: 20px;
}

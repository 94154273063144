.scraper-btn {
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
   gap: 5px !important;
   border-radius: 10px !important;
   height: 48px;
   border-radius: 15px !important;
}

.spinner {
   display: inline-block;
   width: 24px;
   height: 24px;
   border-radius: 50%;
   background: radial-gradient(farthest-side, #ffffff 94%, #f0818100) top/3.8px 3.8px no-repeat,
      conic-gradient(#0000 30%, #ffffff);
   -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 3.8px), #000 0);
   animation: spinner-c7wet2 1s infinite linear;
}

@keyframes spinner-c7wet2 {
   100% {
      transform: rotate(1turn);
   }
}
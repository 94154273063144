.sidebar {
    background-color: #ffffff;
    padding-top: 20px;
    border: none !important;
}

.sidebar  .logo {
    width: 70% ;
    object-fit: contain;
}

.sidebar .nav-link {
    display: flex;
    align-items: center;
    color: var(--muted-text) !important;
    padding: 10px 20px;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
}

.sidebar  .nav-link.active, .nav-link:hover {
    background-color: var(--primary);
    color: var(--light-text) !important;
}

.sidebar  .nav-link .fa-icon {
    margin-right: 10px;
}

.sidebar  .mt-auto .nav-link {
    margin-bottom: 10px;
}

/* Estilos para o Dropdown de busca */
.search-bar .input-group {
    width: 100%;
    position: relative;
    z-index: 1;
}

.search-container.focused .input-group {
    z-index: 1060;
}

.search-bar .dropdown-menu {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #dee2e6;
    box-sizing: border-box;
    z-index: 1060;
    margin-top: 0;
}

.search-bar .dropdown-item {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    cursor: pointer;
}

.search-bar .dropdown-item:hover {
    background-color: #f8f9fa;
}

.search-bar .form-control {
    border-left: none;
    border-radius: 0 15px 15px 0;
}

.search-bar .input-group-text {
    background-color: #fff;
    border-right: none;
}

.magnifying-glass {
    border-radius: 0;
}

.rounded-circle {
    border-radius: 50%;
}

/* Overlay para escurecer o fundo */
.search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1040;
    display: none;
}

.search-overlay.active {
    display: block;
}

/* Estilos personalizados para o header do dropdown */
.dropdown-header-custom {
    font-weight: bold;
    background-color: #f8f9fa;
    padding: 0.5rem 1rem;
}

.search-dropdown {
    max-width: 560px;
    border-radius: 0 0 15px 15px !important;
}

.search-dropdown::-webkit-scrollbar {
    width: 8px;
    background: transparent;
}

.search-dropdown::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgb(110, 107, 107);
}

.search-bar input {
    transition: border-radius 0.3s ease;
}

.search-bar .input-group-text {
    border-radius: 15px 0 0 15px;
    transition: border-radius 0.3s ease;
}

.search-container.focused .search-bar input {
    border-radius: 0 15px 0 0 !important;
}

.search-container.focused .search-bar .input-group-text {
    border-radius: 15px 0 0 0 !important;
}

.not-found-container {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.not-found-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.not-found-container p {
    font-size: 1.25rem;
    margin-bottom: 30px;
}

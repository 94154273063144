.scraper-input .input-group {
    height: 48px;
}

.scraper-input .input-group input {
    border-radius: 0 15px 15px 0 !important;
}

.scraper-input-border .input-group input {
    height: 48px;
    border-radius: 15px !important;
}

.scraper-input-border .input-group-text {
    border-radius: 15px 0 0 15px !important;
}

.scraper-input .input-group-text {
    border-radius: 15px 0 0 15px !important;
}
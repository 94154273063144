.plus-icon {
  background-color: #0077B5;
  color: white;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-top: -30px;
}

.bottom-navbar .navbar-nav .nav-link {
  color: black;
}

.bottom-navbar .nav-link.active {
  background-color: transparent !important;
  color: #0077B5 !important;
}

.bottom-navbar .nav-link:hover {
  background-color: transparent !important;
  color: #0077B5 !important;
}

.bottom-navbar .navbar-nav .nav-link span {
  font-size: 12px;
}